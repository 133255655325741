import { useState, useEffect } from "react";

export const useClick = () => {
  const [clicked, setClicked] = useState(false);

  const clickHandler = (bool) => {
    setClicked(bool);
  };

  useEffect(() => {
    const resetClicked = () => {
      setTimeout(() => {
        setClicked(false);
      }, 5000);
    };
    resetClicked();
  }, [clicked]);

  return { clicked, clickHandler };
};
